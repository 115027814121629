<template>
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">{{ user.id ? 'Edit' : 'New' }} User
                <div
                  v-if="!isEvaluator"
                  class="mt-1"
                >
                  <div class="form-check form-check-success form-switch d-flex align-items-center">
                    <input
                      id="publishContent"
                      v-model="user.active"
                      type="checkbox"
                      class="form-check-input"
                    >
                    <label
                      class="form-check-label"
                      for="publishContent"
                    ><small class="ms-1">Active</small></label>
                  </div>
                </div>
              </h2>
            </div>
          </div>
        </div>
        <div
          v-if="!isEvaluator"
          class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none"
        >
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Back to areas & groups list"
              @click="$router.push({name: 'admin.users.index'})"
            ><i data-feather="chevron-left" /></a>
            <!-- <a
              class="btn btn-icon btn-flat-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Senior call settings"
            ><i data-feather="settings" /></a> -->
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div
          v-if="errors != ''"
          class="alert alert-danger mt-1 p-1"
        >
          {{ errors }}
        </div>
        <div class="row">
          <form
            id="edit-form"
            action=""
          >
            <div class="col-12">
              <div class="card">
                <div class="card-header">
                  <h3 class="cart-title">
                    <a data-action="collapse">Content</a>
                  </h3>
                  <div class="heading-elements">
                    <ul class="list-inline mb-0">
                      <li>
                        <a data-action="collapse"><i data-feather="chevron-down" /></a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="card-content collapse show">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-3">
                        <div class="mb-1 form-group required">
                          <label
                            for=""
                            class="form-label"
                          >First Name</label>
                          <input
                            v-model="user.first_name"
                            aria-autocomplete="off"
                            autocomplete="new-name"
                            class="form-control"
                            type="text"
                          >
                        </div>
                      </div>
                      <div class="col-sm-3">
                        <div class="mb-1 form-group required">
                          <label
                            for=""
                            class="form-label"
                          >Last Name 1</label>
                          <input
                            v-model="user.surname_1"
                            aria-autocomplete="off"
                            autocomplete="new-surname-1"
                            class="form-control"
                            type="text"
                          >
                        </div>
                      </div>
                      <div class="col-sm-3">
                        <div class="mb-1 form-group">
                          <label
                            for=""
                            class="form-label"
                          >Last Name 2</label>
                          <input
                            v-model="user.surname_2"
                            aria-autocomplete="off"
                            autocomplete="new-surname-2"
                            class="form-control"
                            type="text"
                          >
                        </div>
                      </div>
                      <div class="col-sm-3">
                        <div class="mb-1 form-group required">
                          <label
                            for=""
                            class="form-label"
                          >Password</label>
                          <input
                            v-model="user.password"
                            aria-autocomplete="off"
                            autocomplete="new-password"
                            :disabled="dissablePassword"
                            class="form-control"
                            type="password"
                          >
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="mb-1 form-group required">
                          <label
                            for=""
                            class="form-label"
                          >Email</label>
                          <input
                            v-model="user.email"
                            aria-autocomplete="off"
                            autocomplete="new-email"
                            class="form-control"
                            type="email"
                          >
                        </div>
                      </div>
                      <div
                        v-if="!isEvaluator"
                        class="col-sm-2"
                      >
                        <div class="mb-1 form-group required">
                          <label
                            for=""
                            class="form-label"
                          >Roles</label>
                          <v-select
                            v-model="selectedRoles"
                            label="name"
                            multiple
                            :options="roles.filter(o => selectedRoles.filter(e => e.name == o.name).length == 0)"
                            :get-option-key="option => option.id"
                            @input="checkValues"
                          />
                        </div>
                      </div>
                      <div
                        v-if="selectedRoles.length === 1 && selectedRoles[0].name === 'cae'"
                        class="col-sm-2"
                      >
                        <div class="mb-1">
                          <label
                            for="name"
                            class="form-label"
                          >CAE Host</label>
                          <v-select
                            v-model="selectedCaeHost"
                            label="name"
                            :options="institutions"
                            :get-option-key="option => option.name"
                            @search="onSearch({ name: $event }, 'institutions/filter')"
                          />
                        </div>
                      </div>
                      <div
                        v-if="selectedRoles.length === 1 && selectedRoles[0].name === 'university'"
                        class="col-sm-2"
                      >
                        <div class="mb-1">
                          <label
                            for="name"
                            class="form-label"
                          >University</label>
                          <v-select
                            v-model="selectedUniversity"
                            label="name"
                            :options="institutions"
                            :get-option-key="option => option.name"
                            @search="onSearch({ name: $event, typeName: 'University' }, 'institutions/filter')"
                          />
                        </div>
                      </div>
                      <div
                        v-if="loggedUser.roles.includes('super-admin')"
                        class="col-sm-2 my-auto"
                      >
                        <div class="form-check form-check-success form-switch d-flex align-items-center">
                          <input
                            id="publishContent"
                            v-model="user.can_sign"
                            aria-autocomplete="off"
                            autocomplete="new-can-sign"
                            type="checkbox"
                            class="form-check-input"
                          >
                          <label
                            class="ms-1 form-check-label"
                            for="publishContent"
                          >Can sign the host histories</label>
                        </div>
                      </div>
                      <div
                        v-if="loggedUser.roles.includes('super-admin')"
                        class="col-sm-2 my-auto"
                      >
                        <div class="form-check form-check-success form-switch d-flex align-items-center">
                          <input
                            id="publishContent"
                            v-model="user.test"
                            aria-autocomplete="off"
                            autocomplete="new-test"
                            type="checkbox"
                            class="form-check-input"
                          >
                          <label
                            class="ms-1 form-check-label"
                            for="publishContent"
                          >Test user</label>
                        </div>
                      </div>
                      <div
                        class="col-6"
                      >
                        <div class="mb-1 form-group required">
                          <label
                            for=""
                            class="form-label"
                          >Area</label>
                          <v-select
                            v-model="user.areas"
                            label="name"
                            :options="areas"
                            :get-option-key="option => option.id"
                            :clearable="false"
                          />
                        </div>
                      </div>
                      <div
                        v-if="loggedUser.roles.includes('super-admin')"
                        class="col-6"
                      >
                        <div
                          class="form-check form-check-success form-switch d-flex align-items-center"
                          style="margin-top: 25px;"
                        >
                          <input
                            id="publishContent"
                            type="checkbox"
                            class="form-check-input"
                            @click="willSendCredentials"
                          >
                          <label
                            class="form-check-label"
                            for="publishContent"
                          ><small class="ms-1">Send credentials</small></label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="!isEvaluator"
                class="card"
              >
                <div
                  v-b-toggle="`collapsePermissionsManagement`"
                  class="card-header"
                >
                  <h3 class="cart-title">
                    <a data-action="collapse">Manage permissions</a>
                  </h3>
                  <input
                    type="checkbox"
                    class="mx-1 form-checkbox-control"
                    @click="checkAllPermissions($event)"
                  >
                  <div class="heading-elements">
                    <ul class="list-inline mb-0">
                      <li>
                        <a data-action="collapse"><i data-feather="chevron-down" /></a>
                      </li>
                    </ul>
                  </div>
                </div>
                <b-collapse
                  :id="`collapsePermissionsManagement`"
                >
                  <div class="card-content">
                    <div class="card-body">
                      <div
                        v-if="!loading"
                        class="row"
                      >
                        <ManagePermission
                          v-for="(permission, index) in permissions"
                          :key="index"
                          :permission-name="index"
                          :item="permission"
                          :direct-permissions="user.direct_permissions"
                          :inherit-permissions="user.inherited_permissions"
                          :current-id="user.id"
                          :assign-to="'user'"
                          :check-all="checkAll"
                          @saveAdditionalPermissions="saveAdditionalPermissions($event)"
                        />
                      </div>
                    </div>
                  </div>
                </b-collapse>
              </div>
              <div class="card">
                <div class="card-header">
                  <h3 class="cart-title">
                    <a data-action="collapse">Additional Information</a>
                  </h3>
                  <div class="heading-elements">
                    <ul class="list-inline mb-0">
                      <li>
                        <a data-action="collapse"><i data-feather="chevron-down" /></a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="card-content collapse show">
                  <div
                    v-if="!loading"
                    class="card-body"
                  >
                    <div
                      v-if="!isEvaluator"
                      class="row"
                    >
                      <div class="col-sm-5">
                        <div class="mb-1 form-group">
                          <label
                            for=""
                            class="form-label"
                          >Identify document</label>
                          <input
                            v-model="user.identify_document"
                            class="form-control"
                            type="text"
                          >
                        </div>
                      </div>
                      <div class="col-sm-5">
                        <div class="mb-1 form-group">
                          <label
                            for=""
                            class="form-label"
                          >Birthday</label>
                          <date-picker
                            v-model="user.birthday"
                            format="D MMM Y"
                            value-type="format"
                            class="w-100"
                          />
                        </div>
                      </div>
                      <div class="col-sm-2">
                        <div class="mb-1 form-group">
                          <label
                            for=""
                            class="form-label"
                          >Gender</label>
                          <v-select
                            v-model="user.genre"
                            label="name"
                            :options="genders"
                            :get-option-key="option => option.id"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="isEvaluator"
                      class="row"
                    >
                      <div
                        class="col-sm-4"
                      >
                        <div class="mb-1 form-group">
                          <label
                            for=""
                            class="form-label"
                          >Institution</label>
                          <input
                            v-model="user.institution_name"
                            class="form-control"
                            type="text"
                          >
                        </div>
                      </div>
                      <div
                        class="col-sm-4"
                      >
                        <div class="mb-1 form-group">
                          <label
                            for=""
                            class="form-label"
                          >Department</label>
                          <input
                            v-model="user.department_name"
                            class="form-control"
                            type="text"
                          >
                        </div>
                      </div>
                      <div
                        class="col-sm-4"
                      >
                        <div class="mb-1 form-group">
                          <label
                            for=""
                            class="form-label"
                          >Position</label>
                          <input
                            v-model="user.position_name"
                            class="form-control"
                            type="text"
                          >
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-5">
                        <div class="mb-1 form-group">
                          <label
                            for=""
                            class="form-label"
                          >Work address</label>
                          <input
                            v-model="user.address"
                            class="form-control"
                            type="text"
                          >
                        </div>
                      </div>
                      <div class="col-sm-5">
                        <div class="mb-1 form-group">
                          <label
                            for=""
                            class="form-label"
                          >City</label>
                          <input
                            v-model="user.city"
                            class="form-control"
                            type="text"
                          >
                        </div>
                      </div>
                      <div class="col-sm-2">
                        <div class="mb-1 form-group">
                          <label
                            for=""
                            class="form-label"
                          >Postal code</label>
                          <input
                            v-model="user.postal_code"
                            class="form-control"
                            type="text"
                          >
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-15">
                        <div class="mb-1">
                          <label
                            class="form-label"
                            for=""
                          >Prefix</label>
                          <v-select
                            v-model="user.phone_prefix_aux"
                            label="phone_code"
                            :options="citizenships"
                            :get-option-key="option => option.id"
                            :clearable="false"
                          />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="mb-1">
                          <label
                            class="form-label"
                            for=""
                          >Mobile phone </label>
                          <input
                            v-model="user.phone"
                            type="text"
                            class="form-control"
                          >
                        </div>
                      </div>
                      <div class="col-md-15">
                        <div class="mb-1">
                          <label
                            class="form-label"
                            for=""
                          >Prefix</label>
                          <v-select
                            v-model="user.work_phone_prefix_aux"
                            label="phone_code"
                            :options="citizenships"
                            :clearable="false"
                            :get-option-key="option => option.id"
                          />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="mb-1">
                          <label
                            class="form-label"
                            for=""
                          >Work phone </label>
                          <input
                            v-model="user.work_phone"
                            type="text"
                            class="form-control"
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-content collapse show">
                  <div
                    v-if="loading"
                    class="card-body"
                  >
                    <div
                      class="col-12 text-center no-result no-items"
                    >
                      <div class="loading">
                        <div class="effect-1 effects" />
                        <div class="effect-2 effects" />
                        <div class="effect-3 effects" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <MainERC
                    v-if="!loading"
                    :user="user"
                    :selected-roles="selectedRoles"
                  />
                </div>
                <div class="col-6">
                  <Keywords
                    v-if="!loading"
                    :user="user"
                    :selected-roles="selectedRoles"
                  />
                </div>
              </div>
            </div>
          </form>

        </div>

        <div class="page-actions">
          <button
            v-if="!sending"
            id="type-success"
            class="btn btn-success"
            @click="save"
          >
            <i data-feather="save" />
            <span class="ms-25 align-middle">{{ user.sendCredentials ? 'Send credentials,' : '' }} Save and Exit</span>
          </button>
          <button
            v-else
            class="btn btn-outline-success"
            type="button"
            disabled=""
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
            <span class="ms-25 align-middle">Saving...</span>
          </button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import { BCollapse } from 'bootstrap-vue'
import DatePicker from 'vue2-datepicker'
// import ManageRoles from './components/ManageRoles.vue'
import ManagePermission from '../../partials/components/ManagePermission.vue'
import MainERC from '../../partials/components/profile/MainERC.vue'
import Keywords from '../../partials/components/profile/Keywords.vue'
import 'vue2-datepicker/index.css'

export default {
  components: {
    vSelect,
    // ManageRoles,
    ManagePermission,
    BCollapse,
    DatePicker,
    MainERC,
    Keywords,
  },
  data() {
    return {
      userId: this.$route.params.id,
      sending: false,
      errors: '',
      selectedRoles: [],
      selectedUniversity: null,
      selectedCaeHost: null,
      rolesIds: [],
      selectedPermissions: [],
      loading: true,
      checkAll: false,
      dissablePassword: false,
      isEvaluator: false,
      auxRoles: [],
    }
  },
  computed: {
    ...mapGetters({
      user: 'users/user',
      roles: 'roles/allRoles',
      permissions: 'permissions/permissions',
      citizenships: 'citizenships/items',
      areas: 'areas/areas',
      genders: 'genres/items',
      loggedUser: 'auth/admin',
      institutions: 'institutions/institutions',
    }),
  },
  async mounted() {
    if (this.areas.length === 0) {
      this.$store.dispatch('areas/filter', this.$store.getters['filters/filters'])
    }

    if (this.loggedUser.roles.includes('evaluator') || this.loggedUser.roles.includes('awarded') || this.loggedUser.roles.includes('ondeuev')) {
      this.isEvaluator = true
      await this.$store.dispatch('users/fetchUser', this.loggedUser.id)
    } else if (this.userId) {
      await this.$store.dispatch('users/fetchUser', this.userId)
      this.selectedRoles = this.user.roles
      if (this.user.roles.map(e => e.name).includes('university')) {
        this.selectedUniversity = { ...this.user.hosts[0] }
      }

      if (this.user.roles.map(e => e.name).includes('cae')) {
        this.selectedCaeHost = { ...this.user.hosts[0] }
      }
    } else {
      await this.$store.dispatch('users/cleanUser')
    }

    await this.$store.dispatch('roles/fetchAll')
    if (this.$route.query.preselectedRoles) {
      this.auxRoles = this.$route.query.preselectedRoles.split(' ')
      this.selectedRoles = this.roles.filter(o => this.auxRoles.filter(e => o.name === e).length !== 0)
    }
    await this.$store.dispatch('permissions/fetch')
    await this.$store.dispatch('citizenships/fetch')
    await this.$store.dispatch('genres/fetch')
    this.loading = false
  },
  methods: {
    async onSearch(search, store) {
      search.active = true
      if (search.name !== '' && search.name.length > 2) {
        await this.$store.dispatch(store, search)
      }
    },
    async save() {
      const { sendCredentials } = this.user
      this.sending = true
      this.rolesIds = []
      this.selectedRoles.map(item => {
        this.rolesIds.push(item.id)
        return item
      })
      this.user.role_ids = this.rolesIds
      if (this.selectedUniversity && this.selectedUniversity.id) {
        this.user.university = this.selectedUniversity.id
      }

      if (this.selectedCaeHost && this.selectedCaeHost.id) {
        this.user.caeHost = this.selectedCaeHost.id
      }

      this.user.additional_permissions = this.user.direct_permissions

      if (!this.user.keywords) {
        this.user.keywords = []
      }

      if (this.loggedUser.roles.includes('super-admin')) {
        this.user.keywords = null
      }

      try {
        if (this.loggedUser.roles.includes('evaluator') || this.loggedUser.roles.includes('moderator') || this.loggedUser.roles.includes('awarded')) {
          if (this.user.main_ercs.level1.length === 0 || (this.user.main_ercs.level2 === 0 || !this.user.main_ercs.level2)) {
            Vue.swal('Error on saving the user.', 'Please, fill up all the required fields', 'error')
          } else {
            await this.$store.dispatch('users/update', { id: this.loggedUser.id, data: this.user })
          }
        } else if (this.userId) {
          await this.$store.dispatch('users/update', { id: this.userId, data: this.user })
        } else {
          await this.$store.dispatch('users/create', this.user)
        }
        this.errors = ''
      } catch (e) {
        this.errors = e.response.data.message
      }

      if (this.isEvaluator ? ((this.loggedUser.roles.includes('evaluator') || this.loggedUser.roles.includes('awarded')) && this.errors === '' && this.user.id && (this.user.main_ercs.level1.length !== 0 && this.user.main_ercs.level2.length !== 0)) : (this.errors === '' && this.user.id)) {
        Vue.swal(sendCredentials ? 'The user has been saved correctly and credentials has been sent' : 'The user has been saved correctly', '', 'success').then(() => {
          if (!this.loggedUser.roles.includes('evaluator') && !this.loggedUser.roles.includes('awarded')) {
            this.$router.push({ name: 'admin.users.index' })
          } else {
            this.$store.dispatch('users/fetchUser', this.loggedUser.id)
          }
        })
      } else {
        // Vue.swal
      }

      this.sending = false
    },
    checkValues() {
      let permissions = []
      this.selectedRoles.map(item => {
        permissions = permissions.concat(item.permissions_names)
        return item
      })
      this.user.inherited_permissions = permissions
    },
    saveAdditionalPermissions(permissions) {
      if (!this.user.id) {
        if (!Array.isArray(permissions)) {
          if (this.user.direct_permissions.includes(permissions)) {
            this.user.direct_permissions.splice(this.user.direct_permissions.indexOf(permissions), 1)
          } else {
            this.user.direct_permissions.push(permissions)
          }
        }
      } else {
        this.user.direct_permissions = permissions
      }
    },
    checkAllPermissions(event) {
      event.stopPropagation()
      this.checkAll = event.target.checked
    },
    willSendCredentials(event) {
      this.user.sendCredentials = event.target.checked
      this.dissablePassword = event.target.checked
      this.user.password = this.dissablePassword ? null : this.user.password
    },
  },

}
</script>
